<template>
  <v-row>
    <v-col>
      <div class="mb-3">
        <div class="overline">
          <span v-t="'productDetails.solutionType'" />
        </div>
        <div>{{ contract.product.type }}</div>
      </div>
      <div class="mb-3">
        <div class="overline">
          <span v-t="'productDetails.numberOfUsers'" />
        </div>
        <div>
          {{ numberOfUsers }}
        </div>
      </div>
      <div class="mb-3">
        <div class="overline">
          <span v-t="'productDetails.subscribedUntil'" />
        </div>
        <div>{{ contract.product.expiration | formatDate }}</div>
      </div>
    </v-col>
    <v-col>
      <div class="mb-3">
        <div class="overline">
          <span v-t="'productDetails.plugins'" />
        </div>
        <div>
          <div v-if="expandPlugins">
            <div
              v-for="plugin in contract.product.plugins"
              :key="`plugin-${plugin.easy_price_book_product_id}`"
            >
              {{ plugin.name }}
            </div>
            <div
              class="toggle-plugins-show"
              data-cy="less"
              @click="togglePluginsShow"
            >
              <span v-t="'productDetails.less'" />
            </div>
          </div>
          <div v-else>
            <div v-if="contract.product.plugins[0]">
              {{ contract.product.plugins[0].name }}
            </div>
            <div v-if="contract.product.plugins[1]">
              {{ contract.product.plugins[1].name }}
            </div>
            <div
              v-if="contract.product.plugins.length > 2"
              class="toggle-plugins-show"
              data-cy="more"
              @click="togglePluginsShow"
            >
              {{ '+' + (contract.product.plugins.length - 2) }}
              <span v-t="'productDetails.more'" />
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => {
    return {
      expandPlugins: false
    }
  },
  computed: {
    ...mapGetters('client', ['client']),
    contract () {
      const routeParams = this.$route.params
      return this.$store.getters['contract/contract']({
        clientId: routeParams.clientId,
        contractId: routeParams.contractId
      })
    },
    numberOfUsers () {
      if (!this.contract || (this.contract && !this.contract.product)) {
        return null
      }

      const { maxUsersCount, usersCount } = this.contract.product

      return `${maxUsersCount} (${usersCount} ${this.$t('productDetails.activeUsers')})`
    }
  },
  methods: {
    togglePluginsShow () {
      this.expandPlugins = !this.expandPlugins
    }
  }
}
</script>
